@import url(https://fonts.googleapis.com/css?family=Crimson+Pro&effect=fire-animation);

body {
  font-family: "Crimson Pro";
  text-align: center;
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8.8px + 1.75vmin);
  color: #0ff092;
}

.container {
  text-align: center;
  margin-bottom: 20px;
}

.containersoundcloud {
  width: 100%;
  height: 100%;
}

button {
  background-color: #0ff092;
  border: 2px solid #ffffff;
  border-radius: 12px;
  color: rgb(0, 0, 0);
  padding: 12px 20px;
  text-align: center;
  width: 110px;
  display: inline-block;
  font-size: 16px;
  font-family: "Crimson Pro";
  margin: 4px 8px;
  cursor: pointer;
}

input[type="checkbox"] {
  background-color: #0ff092;
  border: 2px solid #ffffff;
  border-radius: 12px;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

button:focus {
  outline: none;
}

button:active {
  background-color: #ffffff;
}

input[type="text"] {
  background-color: #ffffff;
  border: 2px solid #0ff092;
  border-radius: 12px;
  color: rgb(0, 0, 0);
  padding: 12px 20px;
  text-align: center;
  width: 160px;
  font-size: 16px;
  font-family: "Crimson Pro";
  margin: 4px 8px;
  box-sizing: border-box;
}

.fire-button {
  background-color: #ffe8b5;
  border: 2px solid #fdae05;
  border-radius: 12px;
  color: rgb(0, 0, 0);
  padding: 12px 20px;
  text-align: center;
  width: 110px;
  display: inline-block;
  font-size: 16px;
  font-family: "Crimson Pro";
  margin: 4px 8px;
  cursor: pointer;
}

.fire-input[type="text"] {
  background-color: #ffffff;
  border: 2px solid #fdae05;
  border-radius: 12px;
  color: rgb(0, 0, 0);
  padding: 12px 20px;
  text-align: center;
  width: 160px;
  font-size: 16px;
  font-family: "Crimson Pro";
  margin: 4px 8px;
  box-sizing: border-box;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}

.App {
  text-align: center;
  width: 600px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .App {
    width: 360px;
  }

  .container {
    margin-bottom: 40px;
  }

  button {
    padding: 10px 15px;
    width: 80px;
    margin: 4px 4px;
  }

  input[type="text"] {
    padding: 10px 15px;
    width: 160px;
    margin: 4px 4px;
  }

  .fire-button {
    background-color: #ffe8b5;
    border: 2px solid #fdae05;
    padding: 10px 15px;
    width: 80px;
    margin: 4px 4px;
  }

  .fire-input[type="text"] {
    background-color: #ffffff;
    border: 2px solid #fdae05;
    padding: 10px 15px;
    width: 160px;
    margin: 4px 4px;
  }

  .containersoundcloud {
    height: 20px;
  }
}
